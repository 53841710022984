import { trackEvent } from "../../utils/trackingUtils";

const ACCORDION_CLASS = "js-accordion";
const ACCORDION_SELECTOR = `.${ACCORDION_CLASS}`;
const ACCORDION_TOGGLE_SELECTOR = ".js-accordion-toggle";
const CSS_TOGGLED = "accordion--toggled";

/**
 * Initialises accordions.
 */

const accordion = () => {
  const hide = ($el: HTMLElement) => {
    $el.classList.remove(CSS_TOGGLED);
    $el.setAttribute("aria-expanded", "false");
  };

  const show = ($el: HTMLElement, ignoreTracking: boolean) => {
    $el.classList.add(CSS_TOGGLED);
    $el.setAttribute("aria-expanded", "true");
    $el.focus();

    if (!ignoreTracking && $el.children.length > 0) {
      trackEvent("faq", "expand", $el.children[0].textContent);
    }
  };

  const toggleVisibility = (e: MouseEvent) => {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }

    const $clicked = e.currentTarget as HTMLElement;
    const target = $clicked.dataset.target as string;
    const $target = document.querySelector(target) as HTMLElement;

    $clicked.blur();

    if ($target.classList.contains(CSS_TOGGLED)) {
      hide($target);
      return;
    }

    show($target, false);
  };

  if (!document.querySelectorAll(ACCORDION_TOGGLE_SELECTOR).length) {
    return;
  }

  document.querySelectorAll(ACCORDION_SELECTOR).forEach(($el: Element) => {
    const $htmlElement = $el as HTMLElement;

    $htmlElement.classList.remove(CSS_TOGGLED);
    $htmlElement.setAttribute("aria-expanded", "false");
  });

  show(document.querySelectorAll(ACCORDION_SELECTOR)[0] as HTMLElement, true);

  document
    .querySelectorAll(ACCORDION_TOGGLE_SELECTOR)
    .forEach(($el: Element) => {
      const $htmlElement = $el as HTMLElement;

      $htmlElement.addEventListener("click", toggleVisibility);
    });

  const locationHashChanged = () => {
    const $hash = document.querySelector(window.location.hash) as HTMLElement;
    if ($hash !== null && $hash.classList.contains(ACCORDION_CLASS)) {
      show($hash, true);
    }
  };

  // If the window loads with an # that corresponds to an accordion item, open it by default
  if (window.location.hash) {
    locationHashChanged();
  }

  // If a # link is clicked that corresponds to an accordion item, open it
  window.onhashchange = locationHashChanged;
};

export default accordion;
