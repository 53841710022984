/**
 * Handles toggling for cross save for a platform.
 */

const CONFIG = {
  toggleUpdateErrorMessage:
    'Unfortunately there was an error updating whether cross-save is enabled.',
  requestDelay: 500,
};

const CSS = {
  hidden: 'hidden',
};

const SELECTORS = {
  antiForgeryTokenInput: 'input[name="__RequestVerificationToken"]',
  error: '.js-cross-save-error',
  input: '.js-cross-save-toggle-input',
  root: '.js-cross-save-toggle',
};

interface IToggleDom {
  $antiForgeryTokenInput: HTMLInputElement | null;
  $error: HTMLElement | null;
  $input: HTMLInputElement | null;
}

const instance = ($el: HTMLElement) => {
  const options = {
    platform: $el.getAttribute('data-platform') || '',
  };

  const dom: IToggleDom = {
    $antiForgeryTokenInput: $el.querySelector(SELECTORS.antiForgeryTokenInput),
    $error: document.querySelector(SELECTORS.error),
    $input: $el.querySelector(SELECTORS.input),
  };

  const state = {
    requestDelayTimeout: 0,
  };

  if (!options.platform || !dom.$input || !dom.$antiForgeryTokenInput) {
    return;
  }

  const handleInputChange = () => {
    hideError();

    if (state.requestDelayTimeout) {
      window.clearTimeout(state.requestDelayTimeout);
    }

    state.requestDelayTimeout = window.setTimeout(
      sendToggleRequest,
      CONFIG.requestDelay
    );
  };

  const hideError = () => {
    if (!dom.$error) {
      return;
    }

    dom.$error.classList.add(CSS.hidden);
    dom.$error.innerHTML = '';
  };

  const sendToggleRequest = () => {
    const formData = new FormData();
    formData.append('isEnabled', dom.$input?.checked ? 'true' : 'false');
    formData.append(
      '__RequestVerificationToken',
      dom.$antiForgeryTokenInput?.value ?? ''
    );

    fetch(`/cross-save/${options.platform}`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
      })
      .catch(() => {
        showError(CONFIG.toggleUpdateErrorMessage);
      });
  };

  const showError = (message: string) => {
    if (!dom.$error) {
      return;
    }

    dom.$error.innerHTML = message;
    dom.$error?.classList.remove(CSS.hidden);
  };

  dom.$input.addEventListener('change', handleInputChange);
};

export default () => {
  document.querySelectorAll(SELECTORS.root).forEach(($el: Element) => {
    instance($el as HTMLElement);
  });
};
