/**
 * Lazy loads background imagery (specified through data attribute)
 */

/*
 * 1. How far below (or above) the viewport that assets will be loaded in.
 *    It's better for 'performance feel' if things load when they're close rather than only starting when they become visible
 */

const WITHIN_DISTANCE = "200px"; /* 1 */

const lazyLoadBackground = () => {
  const CLASS = "background-loaded";
  const SELECTOR = ".js-lazy-load-background";

  if (typeof IntersectionObserver === "undefined") {
    document.querySelectorAll(SELECTOR).forEach(($el: Element) => {
      const $htmlElement = $el as HTMLElement;
      const background = $htmlElement.dataset.background as string;
      $htmlElement.style.backgroundImage = `url('${background}')`;
      $htmlElement.classList.add(CLASS);
    });

    return;
  }

  const checkEntry = (entry: IntersectionObserverEntry) => {
    const $entry = entry.target as HTMLHtmlElement;

    if (entry.isIntersecting) {
      const background = $entry.dataset.background as string;
      $entry.style.backgroundImage = `url('${background}')`;
      $entry.classList.add(CLASS);

      return;
    }

    $entry.classList.remove(CLASS);
  };

  const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(checkEntry);
  };

  const observer = new IntersectionObserver(callback, {
    rootMargin: `${WITHIN_DISTANCE} 0px`,
    threshold: 0,
  });

  document.querySelectorAll(SELECTOR).forEach(($el: Element) => {
    observer.observe($el as HTMLElement);
  });
};

export default lazyLoadBackground;
