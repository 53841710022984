/**
 * Shows a modal that displays item history
 * Based on: https://github.com/scottaohara/accessible_itemHistoryModal_window
 */

const SELECTORS = {
  show: ".js-show-item-history",
  close: ".js-close-item-history",
  modal: ".js-item-history",
  content: ".js-item-history-content",
};

const bodyClass = "modal-open";

const itemHistoryModal = () => {
  const $itemHistoryModal = document.querySelector(
    SELECTORS.modal
  ) as HTMLElement;

  if ($itemHistoryModal === null) {
    return;
  }

  const $itemHistoryModalContent = $itemHistoryModal.querySelector(
    SELECTORS.content
  ) as HTMLElement;

  if ($itemHistoryModalContent === null) {
    return;
  }

  const allNodes = document.querySelectorAll("*");

  let modalOpen = false;
  let lastFocus: HTMLElement;
  let i: number;

  const modalShow = () => {
    lastFocus = document.activeElement as HTMLElement;
    $itemHistoryModal.setAttribute("aria-hidden", "false");
    modalOpen = true;
    $itemHistoryModalContent.setAttribute("tabindex", "0");
    $itemHistoryModalContent.focus();
    document.body.classList.add(bodyClass);
  };

  const modalClose = () => {
    if (modalOpen) {
      $itemHistoryModal.setAttribute("aria-hidden", "true");

      if ($itemHistoryModalContent !== null) {
        $itemHistoryModalContent.setAttribute("tabindex", "-1");
      }

      modalOpen = false;
      document.body.classList.remove(bodyClass);
      lastFocus.focus();
    }
  };

  // Restrict focus to the itemHistoryModal window when it's open.
  // Tabbing will just loop through the whole itemHistoryModal.
  // Shift + Tab will allow backup to the top of the itemHistoryModal,
  // and then stop.
  const focusRestrict = (event: FocusEvent) => {
    if (
      modalOpen &&
      $itemHistoryModalContent !== null &&
      !$itemHistoryModalContent.contains(event.target as HTMLElement)
    ) {
      event.stopPropagation();
      $itemHistoryModalContent.focus();
    }
  };

  // Restrict tab focus on elements only inside itemHistoryModal window
  for (i = 0; i < allNodes.length; i++) {
    const item = allNodes.item(i) as HTMLElement;
    item.addEventListener("focus", focusRestrict);
  }

  document.querySelectorAll(SELECTORS.show).forEach(($el: Element) => {
    $el.addEventListener("click", modalShow);
  });

  $itemHistoryModal
    .querySelectorAll(SELECTORS.close)
    .forEach(($el: Element) => {
      $el.addEventListener("click", modalClose);
    });
};

export default itemHistoryModal;
