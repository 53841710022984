import { getOrdinal, leadingZero } from "./numberUtils";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const timeZoneAbbreviated = (date: Date) => {
  const dateMatches = date.toString().match(/\((.+)\)/);

  if (!dateMatches) {
    return;
  }

  const tz = dateMatches[1] || "";

  // In Chrome, new Date().toString() is
  // "Thu Aug 06 2020 16:21:38 GMT+0530 (India Standard Time)"

  // In Safari, new Date().toString() is
  // "Thu Aug 06 2020 16:24:03 GMT+0530 (IST)"

  if (tz.includes(" ")) {
    return tz
      .split(" ")
      .map(([first]) => first)
      .join("");
  } else {
    return tz;
  }
};

export const displayDate = (
  date: Date,
  ignoreTime: boolean = false
): string => {
  return `${days[date.getDay()]} ${getOrdinal(date.getDate())} ${
    months[date.getMonth()]
  }${
    ignoreTime
      ? ""
      : ` at ${leadingZero(date.getHours())}:${leadingZero(
          date.getMinutes()
        )} (${timeZoneAbbreviated(date)})`
  }`;
};

export const displayShortDate = (
  date: Date,
): string => {
  return `${date.getDate()} ${
    months[date.getMonth()]} ${date.getFullYear()}`;
};

export const getTimeUntil = (difference: number) => {
  const seconds = difference / 1000;

  if (difference < 0) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    };
  }

  return {
    hours: Math.floor((seconds / 60 / 60) % 24),
    minutes: Math.floor((seconds / 60) % 60),
    seconds: Math.floor(seconds % 60),
    milliseconds: Math.round((seconds - Math.floor(seconds)) * 1000),
  };
};
