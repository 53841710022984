/**
 * Displays content that has initially been hidden to the user.
 */

import { trackEvent } from "../../utils/trackingUtils";

const CSS = {
  hiddenJs: "hidden-js",
  hiddenVisually: "visually-hidden",
};

const SELECTORS = {
  content: ".js-reveal-content",
  root: ".js-reveal",
  trigger: ".js-reveal-trigger-btn",
};

const instance = ($el: HTMLElement) => {
  const $triggerBtn = $el.querySelector(SELECTORS.trigger);
  const $content = $el.querySelector(SELECTORS.content);

  if (!$triggerBtn || !$content) {
    return;
  }

  const show = () => {
    $content.classList.remove(CSS.hiddenJs);

    $triggerBtn.classList.add(CSS.hiddenVisually);
    $triggerBtn.setAttribute("aria-expanded", "true");

    trackEvent(
      $el.getAttribute("data-category") || "",
      $el.getAttribute("data-action") || "",
      null
    );
  };

  $triggerBtn.addEventListener("click", show);
};

export default () => {
  document.querySelectorAll(SELECTORS.root).forEach(($el: Element) => {
    instance($el as HTMLElement);
  });
};
