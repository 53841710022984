/**
 * Handles showing a confirm dialog when performing a destructive action (such as unlinking or removing an account).
 */

const SELECTORS = {
  confirmDialog: '.js-confirm-dialog',
  confirmDialogTitle: '.js-confirm-dialog-title',
  confirmDialogContent: '.js-confirm-dialog-content',
  confirmBtn: '.js-confirm-dialog-confirm-button',
  cancelBtn: '.js-confirm-dialog-cancel-button',
  destructiveActions: '.js-confirm-dialog-confirmation-target',
  primaryAccount: '.js-primary-account'
};

const dom = {
  $confirmDialog: document.querySelector<HTMLDialogElement>(SELECTORS.confirmDialog),
  $confirmDialogTitle: document.querySelector<HTMLDialogElement>(SELECTORS.confirmDialogTitle),
  $confirmDialogContent: document.querySelector(SELECTORS.confirmDialogContent),
  $confirmBtn: document.querySelector(SELECTORS.confirmBtn),
  $cancelBtn: document.querySelector(SELECTORS.cancelBtn),
  $destructiveActions: document.querySelectorAll(SELECTORS.destructiveActions)
}

const instance = ($el: HTMLElement) => {
  $el.addEventListener('click', handleButtonClick);
}

const handleButtonClick = (e: MouseEvent) => {
  e.preventDefault();
  if (!(e.target instanceof HTMLElement)) {
    return;
  }
  if (!dom.$confirmDialogContent || !dom.$confirmDialogTitle) {
    return;
  }

  const categoryToPlatform = new Map([
    ["xbox", "Xbox"],
    ["psn", "Sony"],
    ["steam", "Steam"],
    ["gog", "GOG"],
    ["nintendo", "Nintendo"]]);

  const $el = e.target;
  const action = $el.getAttribute('data-action');
  const platform = categoryToPlatform.get($el.getAttribute('data-category') ?? '');
  const isPrimaryAccount = $el.closest(SELECTORS.primaryAccount);
  const $linkBeingConfirmed = <HTMLElement>$el.cloneNode(true);
  let confirmDialogTitle = '';
  let confirmDialogContent = '';
  let confirmDialogButtonText = '';

  dom.$confirmBtn?.classList.remove('confirm-dialog__confirm-button--non-destructive');

  if (action === 'remove') {
    confirmDialogTitle = 'Disconnect All Accounts';
    confirmDialogContent = 'Disconnecting all your connected accounts will permanently erase your "Hello User ID", and any save data you have stored on the Hello Games servers. <br/> Are you sure you want to continue and erase your cross-platform data?';
    confirmDialogButtonText = "ERASE DATA";
  }

  if (action === 'unlink') {
      confirmDialogTitle = isPrimaryAccount ? 'Sign out to continue?' : 'Disconnect Account';
      confirmDialogContent = isPrimaryAccount ? `To disconnect this ${platform} account, choose "Sign out", and sign into a different platform account. <br/> Your ${platform} account will then appear under Step 2, where you can disconnect it.` : `Are you sure you want to disconnect your ${platform} account?`;
      confirmDialogButtonText = isPrimaryAccount ? 'SIGN OUT' : 'DISCONNECT'

      if (isPrimaryAccount) {
        dom.$confirmBtn?.classList.add('confirm-dialog__confirm-button--non-destructive');
      }
  }

  dom.$confirmDialogTitle.innerText = confirmDialogTitle
  dom.$confirmDialogContent.innerHTML = confirmDialogContent;
  $linkBeingConfirmed.innerText = confirmDialogButtonText;
  dom.$confirmBtn?.replaceChildren($linkBeingConfirmed);
  dom.$confirmDialog?.showModal();
};

export default () => {
  if (dom.$confirmDialog === null) {
    return;
  }
  dom.$cancelBtn?.addEventListener('click', () => dom.$confirmDialog?.close());
  dom.$destructiveActions.forEach(($el: Element) => {
    if ($el instanceof HTMLElement) {
      instance($el);
    }
  });
};
