/**
 * Displays content that has initially been hidden to the user.
 */

import { trackEvent } from "../../utils/trackingUtils";

const SELECTORS = {
  root: ".js-track-click",
};

const instance = ($el: HTMLElement) => {
  const content = {
    action: $el.getAttribute("data-action") || "",
    category: $el.getAttribute("data-category") || "",
    label: $el.getAttribute("data-label") || "",
  };

  const track = () => {
    trackEvent(content.category, content.action, content.label);
  };

  $el.addEventListener("click", track);
};

export default () => {
  document.querySelectorAll(SELECTORS.root).forEach(($el: Element) => {
    instance($el as HTMLElement);
  });
};
