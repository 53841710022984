export const trackEvent = (
  category: string,
  action: string,
  label: string | null
) => {
  if (!window.gtag || !category || !action) {
    return;
  }

  window.gtag("event", action, {
    event_category: category,
    event_label: label,
  });
};
