/**
 * Manage state of drops collection.
 */

import { displayDate, getTimeUntil } from "../../utils/dateUtils";
import { leadingZero } from "../../utils/numberUtils";

const SELECTORS = {
  countdown: ".js-drops-countdown",
  drop: ".js-drop",
  dropStatus: ".js-drop-status",
  root: ".js-drops",
  status: ".js-drops-status",
};

const COPY = {
  active: "Available on all No Man’s Sky drops enabled streams",
  inactive: "The following items were available on",
};

const CSS = {
  archived: "drop--archived",
};

const activeContent = (emphasis: string) => {
  return `${COPY.active} until <span class="emphasis emphasis--positive">${emphasis}</span>`;
};

const inactiveContent = (content: string) => {
  return `${COPY.inactive} ${content} and are <span class="emphasis emphasis--warning">no longer available</span>`;
};

const pendingContent = (emphasis: string) => {
  return `${COPY.active} on <span class="emphasis">${emphasis}</span>`;
};

const instance = ($el: HTMLElement) => {
  if (!$el.getAttribute("data-end") || !$el.getAttribute("data-start")) {
    return;
  }

  const options = {
    end: new Date(parseInt($el.getAttribute("data-end") || "", 10)),
    start: new Date(parseInt($el.getAttribute("data-start") || "", 10)),
  };

  const dom = {
    $countdown: document.querySelector(SELECTORS.countdown),
    $drops: $el.querySelectorAll(SELECTORS.drop),
    $dropStatus: $el.querySelectorAll(SELECTORS.dropStatus),
    $status: $el.querySelector(SELECTORS.status),
  };

  let timerInterval: number = 0;

  if (!dom.$status) {
    return;
  }

  const updateState = () => {
    const now = new Date().getTime();
    const isActive =
      options.start.getTime() < now && options.end.getTime() > now;
    const hasEnded = now > options.end.getTime();

    // update individual drop status
    if (dom.$dropStatus) {
      dom.$dropStatus.forEach(($dropStatus) => {
        if ($dropStatus.innerHTML !== (isActive ? "Online now" : "")) {
          $dropStatus.innerHTML = isActive ? "Online now" : "";
        }
      });
    }

    if (!dom.$status) {
      return;
    }

    if (!isActive) {
      dom.$status.innerHTML = hasEnded
        ? inactiveContent(displayDate(options.start, true))
        : pendingContent(displayDate(options.start));

      if (hasEnded && dom.$drops) {
        dom.$drops.forEach(($drop) => {
          $drop.classList.add(CSS.archived);
        });
      }

      return;
    }

    dom.$status.innerHTML = activeContent(displayDate(options.end));
    timerInterval = window.setInterval(updateTimer, 10);
  };

  const updateTimer = () => {
    const now = new Date().getTime();
    const endTime = options.end.getTime();
    const until = getTimeUntil(endTime - now);

    if (dom.$countdown) {
      dom.$countdown.innerHTML = `${leadingZero(until.hours)}:${leadingZero(
        until.minutes
      )}:${leadingZero(until.seconds)}.${leadingZero(until.milliseconds).substr(
        0,
        2
      )}`;
    }

    if (now > endTime) {
      window.location.reload();
      clearInterval(timerInterval);
    }
  };

  updateState();
};

export default () => {
  document.querySelectorAll(SELECTORS.root).forEach(($el: Element) => {
    instance($el as HTMLElement);
  });
};
