export const getOrdinal = (value: number): string => {
  const s = ["th", "st", "nd", "rd"];
  const v = value % 100;
  return value + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const leadingZero = (
  unit: number,
  ignoreZero: boolean = false
): string => {
  if (ignoreZero && unit === 0) {
    return "0";
  }

  return unit < 10 ? `0${unit}` : unit.toString();
};
