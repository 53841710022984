/**
 * Manages displaying which drops the user is entitled to.
 */

import { displayShortDate } from "../../utils/dateUtils";
import { IEntitlement } from "./dto/entitlement";
import fetchEntitlements from "./service/fetchEntitlements";

const CSS = {
  dropUnlocked: "drop__unlocked",
  tableCell: "table__cell",
  tableCellDate: "table__cell--date",
  tableRow: "table__row",
};

const SELECTORS = {
  drop: ".js-drop",
  dropRequirement: ".js-drop-requirement",
  dropStatus: ".js-drop-status",
  entitlementsTable: ".js-entitlements-table",
  root: ".js-entitlements",
};

const TEXT = {
  unlocked: "Success! Item unlocked",
  empty: `<h3>No items unlocked</h3><p>Nothing here yet! Watch No Man's Sky streams on Twitch when drops are enabled in order to earn rewards.</p><a class="btn btn--primary" href="https://www.twitch.tv/directory/game/No%20Man's%20Sky?tl=c2542d6d-cd10-4532-919b-3d19f30a768b">No Man's Sky on Twitch</a>`,
};

const instance = ($el: HTMLElement) => {
  if ($el.getAttribute("data-entitlements") !== "true") {
    return;
  }

  const updateDom = (entitlements: IEntitlement[]) => {
    updateDrops(entitlements);
    updateEntitlements(entitlements);
  };

  const updateDrops = (entitlements: IEntitlement[]) => {
    entitlements.map((entitlement: IEntitlement) => {
      const $drop = document.querySelector(
        `${SELECTORS.drop}[data-ids*="${entitlement.benefitId}"]`
      );

      if (!$drop || $drop.querySelector(".js-drop-unlocked")) {
        return;
      }

      $drop.querySelector(SELECTORS.dropRequirement)?.remove();
      $drop.querySelector(SELECTORS.dropStatus)?.remove();

      const $unlockStatus = document.createElement("div");
      $unlockStatus.innerHTML = `<div class="drop__unlocked-icon js-drop-unlocked">Check</div><div class="drop__unlocked-text">${TEXT.unlocked}</div>`;
      $unlockStatus.classList.add(CSS.dropUnlocked);
      $drop.appendChild($unlockStatus);
    });
  };

  const updateEntitlements = (entitlements: IEntitlement[]) => {
    const $entitlementsTable = document.querySelector(
      SELECTORS.entitlementsTable
    );

    if (!$entitlementsTable) {
      return;
    }

    if (!entitlements.length) {
      $entitlementsTable.outerHTML = TEXT.empty;
      return;
    }

    entitlements.map((entitlement: IEntitlement) => {
      const $row = document.createElement("li");
      $row.classList.add(CSS.tableRow);

      const $cellName = document.createElement("div");
      $cellName.classList.add(CSS.tableCell);
      $cellName.innerHTML = entitlement.name;
      $row.appendChild($cellName);

      const $cellTimestamp = document.createElement("div");
      $cellTimestamp.classList.add(CSS.tableCell);
      $cellTimestamp.classList.add(CSS.tableCellDate);
      $cellTimestamp.innerHTML = displayShortDate(
        new Date(entitlement.timestamp)
      );
      $row.appendChild($cellTimestamp);

      $entitlementsTable.appendChild($row);
    });
  };

  fetchEntitlements().then(updateDom);
};

export default () => {
  document.querySelectorAll(SELECTORS.root).forEach(($el: Element) => {
    instance($el as HTMLElement);
  });
};
